.createPatientModal-cont {
  border-radius: 8px;
  width: 100%;
  max-width: 520px;
  &__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    &__divider {
      margin: 0px;
      width: 100%;
    }
    &__formItemWrapper {
      width: 100%;
      display: flex;
      gap: 24px;
      align-items: flex-start;
      &__uploadwrap {
        width: 100%;
        &__formItem {
          width: 100%;
          &__upload {
            &__btn {
              height: 32px !important;
              width: 100%;
              width: 472px !important;
            }
          }
        }
      }
      &__formItem {
        width: 100%;
        max-width: 224px;
        &__text {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
        }
        &__datePicker {
          width: 100%;
        }
      }
      &__formItemAddress {
        width: 100%;
      }
    }
  }
  &__btn {
    width: 100%;
    max-width: 80px;
  }
  .ant-form-item-required.ant-form-item-no-colon::before {
    content: "" !important;
  }
}
@media screen and (max-width: 600px) {
  .createPatientModal-cont {
    max-width: 300px;
    &__form {
      gap: 12px;
      &__formItemWrapper {
        flex-direction: column;
        gap: 12px;
        &__uploadwrap {
          width: 100%;
          &__formItem {
            width: 100%;
            &__upload {
              max-width: 600px;
              &__btn {
                width: 252px !important;
              }
            }
          }
        }
        &__formItem {
          max-width: 300px;
        }
      }
    }

  }
}
