.input_container {
    width: 100%;
    display: flex;
    column-gap: 24px;
    justify-content: space-between;

    &__formItemWrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;

      &__errorText {
        margin-right: auto;
        color: #ff4d4f !important;
        font-size: 14px;
      }
    }

    input {
      // flex-grow: 1;
      padding: 12px 24px;
      border-radius: 6px;
      border: 1px solid var(--colorwhite-60, rgba(255, 255, 255, 0.6));
      background: rgba(22, 20, 37, 0.2);
      color: rgba(255, 255, 255, 0.8);
      font-family: "Barlow", sans-serif;
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      height: 48px;
      width: 100%;

      &::placeholder {
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }