.roleModal-cont {
  width: 100%;
  max-width: 850px;
  border-radius: 8px;
  &__treeCont {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 8px;
  }
  &__btn {
    width: 100%;
    max-width: 80px;
  }
  &__form {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-bottom: 16px;
    gap: 8px;
    &__formItem {
      width: 100%;
      &__text {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
    }
    &__formItemWrapper {
      display: flex;
      width: 100%;
      gap: 4px;
      &__formItem {
        width: 75%;
        &__text {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
        }
      }
    }
    // .ant-form-item-required.ant-form-item-no-colon::before {
    //   content: "" !important;
    // }
    .ant-form-item-required::before {
      content: "" !important;
    }
  }
  // &__checkboxGroup {
  //   width: 100%;
  //   &__cont {
  //     display: flex;
  //     flex-direction: column;
  //     gap: 16px;
  //     align-items: flex-start;
  //   }
  // }
}

@media screen and (max-width: 900px) {
  .roleModal-cont {
    width: 100%;
    max-width: 590px;
    border-radius: 8px;
    &__form {
      display: flex;
      width: 100%;
      flex-direction: column;
      margin-bottom: 16px;
      gap: 8px;

      &__formItemWrapper {
        display: flex;
        width: 100%;
        gap: 4px;
        flex-direction: column;
        &__formItem {
          width: 100%;
          &__text {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
          }
        }
      }
      // .ant-form-item-required.ant-form-item-no-colon::before {
      //   content: "" !important;
      // }
      .ant-form-item-required::before {
        content: "" !important;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .roleModal-cont {
    max-width: 300px;
    &__form {
      margin-bottom: 12px;
      &__formItem {
        width: 100%;
      }
    }
    &__treeCont {
      width: 100%;
      flex-direction: column;

    }
  }
}
