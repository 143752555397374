.order-drawer {
  //prev
  // margin-top: 153px;
  &__table {
    width: 100%;

    &__iconWrapper {
      display: flex;
      width: 40px;
      gap: 8px;
      justify-content: center;
    }
  }

  &__title {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  &__collapseCont {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__collapse {
      width: 100%;
      border-radius: 8px;
      border-top: 2px solid;

      &__title {
        font-size: 20px !important;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
      }

      &__table {
        width: 100%;

        .table-row-text {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
        }

        .tableCelltext-right {
          text-align: right;
        }

        .ant-table-cell.tableCelltext-right {
          text-align: right;
        }
      }

      &__ifo-cont {
        width: 100%;
        display: flex;
        flex-direction: column;

        &__firstLine {
          display: flex;
          width: 100%;

          &__key-cont {
            width: 30%;

            &__title {
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 22px;
            }
          }

          &__value-cont {
            width: 70%;

            &__title {
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
            }
          }

          &__value-cont-withIcon {
            width: 70%;
            display: flex;
            justify-content: space-between;

            &__title {
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
            }

            &__sekeletonInp {
              display: block !important;
              width: 100% !important;
            }
          }

          &__value-contCustomer {
            width: 70%;
            display: flex;
            flex-direction: column;
            gap: 8px;

            &__title {
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
            }

            &__wrapper {
              width: 100%;
              display: flex;
              align-items: center;
              gap: 8px;
            }
          }
        }

        &__divider {
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }

      &__notesCont {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        gap: 16px;
      }
    }
  }

  &__tottal-cont {
    width: 100%;
    display: flex;
    margin-top: 24px;
    min-height: 500px;
    flex-direction: column;
    gap: 26px;

    &__title-cont {
      display: flex;
      justify-content: space-between;
      width: 100%;

      &__title {
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
      }

      &__rate-cont {
        display: flex;
        gap: 8px;

        &__rate {
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 28px;
        }

        &__inp {
          width: 100%;
          max-width: 120px;
          display: flex;
          align-items: center;
        }
      }
    }

    &__options {
      width: 100%;
      display: flex;
      flex-direction: column;

      &__wrapper {
        display: flex;
        justify-content: space-between;

        &__text {
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 22px;
        }

        &__dropdown {
          width: 100%;
          max-width: 200px;
          cursor: pointer;

          &__item {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &__text {
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
            }
          }
        }

        &__dropdownact {
          width: 100%;
          max-width: 200px;
          cursor: pointer;

          &__item {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &__text {
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
            }
          }
        }

        &__dropdownCard {
          width: 100%;
          max-width: 200px;
          cursor: pointer;

          &__item {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &__text {
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
            }
          }
        }

        &__divider {
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .order-drawer {
    //prev
    // margin-top: 121px;

    &__collapseCont {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;

      &__collapse {
        &__ifo-cont {
          width: 100%;
          display: flex;
          flex-direction: column;

          &__firstLine {
            display: flex;
            width: 100%;
            justify-content: space-between;

            &__key-cont {
              width: 40%;
            }

            &__value-cont {
              width: 60%;
            }

            &__value-contCustomer {
              width: 60%;

              &__wrapper {
                flex-wrap: wrap;
              }
            }
          }

          &__divider {
            margin-top: 10px;
            margin-bottom: 10px;
          }
        }
      }
    }

    &__tottal-cont {
      width: 100%;
      display: flex;
      margin-top: 24px;
      min-height: 400px;
      flex-direction: column;
      // padding-left: 16px;
      // padding-right: 16px;
      gap: 26px;

      &__options {
        width: 100%;
        display: flex;
        flex-direction: column;

        &__wrapper {
          display: flex;
          justify-content: space-between;
          gap: 10px;

          &__dropdown {
            width: 100%;
            max-width: 170px;
          }

          &__dropdownact {
            width: 100%;
            max-width: 170px;
          }

          &__dropdownCard {
            width: 100%;
            max-width: 170px;
          }
        }
      }
    }
  }

  // .orderDrawerBigMargin {
  //   margin-top: 235px;
  // }
}

@media screen and (max-width: 900px) {
  .order-drawer {
    //prev 
    // margin-top: 121px;

    &__tottal-cont {
      &__title-cont {
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: 10px;

        &__title {
          font-size: 18px;
        }

        &__rate-cont {
          display: flex;
          gap: 8px;
          align-items: center;

          &__rate {
            font-size: 18px;
          }
        }
      }

      &__options {
        &__wrapper {
          &__dropdown {
            width: 100%;
            max-width: 140px;
          }

          &__dropdownact {
            width: 100%;
            max-width: 140px;
          }

          &__dropdownCard {
            width: 100%;
            max-width: 140px;
          }
        }
      }
    }
  }

  //prev
  // .orderDrawerBigMargin {
  //   margin-top: 235px;
  // }
}