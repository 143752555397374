.createReportModal-cont {
  border-radius: 8px;
  width: 100%;
  max-width: 520px;
  &__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    &__formItemWrapper {
      width: 100%;
      display: flex;
      gap: 24px;
      align-items: flex-start;
      &__formItem {
        width: 100%;
        max-width: 224px;
        &__text {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
        }
      }
      &__formItemType{
        width: 100%;
        max-width: 520px;
      }
    }
    &__divider {
      width: 100%;
      &__text {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
    }
    &__formItemWrapperChecbox {
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 8px;
    }
    &__formItemWrapperRange {
      display: flex;
      width: 100%;
      align-items: flex-start;
      &__formItem {
        width: 100%;
        &__RangePicker {
          width: 100%;
        }
      }
    }
  }
  &__btn {
    width: 100%;
    max-width: 80px;
    height: 32px;
  }
  .ant-form-item-required.ant-form-item-no-colon::before {
    content: "" !important;
  }
  .createReportModal_GetallSpace_FormItem{
    width: 100%;
    max-width: 520px;
  }
}
@media screen and (max-width: 600px){
  .createReportModal-cont {
    max-width: 300px;
    &__form {
     
      gap: 12px;
      &__formItemWrapper {
        flex-direction: column;
        gap: 12px;
        &__formItem {
          width: 100%;
          max-width: 600px;
        }
        &__formItemType{
          width: 100%;
          max-width: 600px;
        }
      }
      &__formItemWrapperChecbox {
        flex-wrap: wrap;
      }
    }
    .createReportModal_GetallSpace_FormItem{
      width: 100%;
      max-width: 600px;
    }
    .ant-form-item-required.ant-form-item-no-colon::before {
      content: "" !important;
    }
  }
}
