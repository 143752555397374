.orders-cont__content__pageCont {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  height: 100%;
  &__roomdetailWrapper {
    width: 100%;
    display: flex;
    gap: 12px;
    &__text {
      font-size: 14px;
      font-style: normal;
      // font-weight: 400;
      // line-height: 22px;
    }
  }
  &__dropdownDriver {
    width: 100%;
    // max-width: 250px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    cursor: pointer;
    &__item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // max-width: 250px;
      &__text {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }
  &__segmentWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    &__withCheckbox{
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }
  &__cardCont {
    display: flex;
    width: 100%;
    gap: 32px;
    justify-content: space-between;
    align-items: center;
    &__card {
      width: 100%;
      max-width: calc(100% - 32px);
      border-radius: 6px;
      border-left: 3px solid;
      &__titleLine {
        width: 100%;
        display: flex;
        justify-content: space-between;
        &__title {
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 22px;
        }
        &__month {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
        }
      }
      &__body {
        width: 100%;
        display: flex;
        justify-content: space-between;
        &__text {
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 28px;
        }
        &__wrapper {
          display: flex;
          width: 100%;
          max-width: 90px;
          gap: 8px;
          justify-content: flex-end;
          &__text {
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
          }
        }
      }
    }
  }
  &__tableCont {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    gap: 24px;
    &__table {
      width: 100%;
      .header-cell-small {
        width: 56px;
      }
      .tableCelltext-right {
        text-align: right;
      }
      .ant-table-cell.tableCelltext-right {
        text-align: right;
      }
      .table-row-text {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
      .tableiconsWrapper {
        &__wrapper {
          width: 24px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          margin-left: auto;
          margin-right: auto;
        }
        display: flex;
        gap: 8px;
        width: 100%;
        max-width: 72px;
      }
    }
    &__pagination {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-bottom: 10px;
    }
  }
}
@media screen and (max-width: 1240px) {
  .orders-cont__content__pageCont {
    &__cardCont {
      gap: 24px;
    }
    &__tableCont {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      &__table {
        width: 100%;
        .header-cell-small {
          width: 56px;
        }
        .tableCelltext-right {
          text-align: right;
        }
        .ant-table-cell.tableCelltext-right {
          text-align: right;
        }
        .table-row-text {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
        }
        .smallTable-row-text {
          max-width: 100px;
        }
        .tableiconsWrapper {
          &__wrapper {
            width: 24px;
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            margin-left: auto;
            margin-right: auto;
          }
          display: flex;
          gap: 8px;
          width: 100%;
          max-width: 72px;
        }
      }
    }
  }
}
@media screen and (max-width: 1080px) {
  .orders-cont__content__pageCont {
    &__cardCont {
      display: flex;
      width: 100%;
      gap: 16px;
      justify-content: space-between;
      align-items: center;
      &__card {
        width: 100%;
        max-width: calc(100% - 16px);
        border-radius: 6px;
        border-left: 3px solid;
        &__body {
          width: 100%;
          display: flex;
          justify-content: space-between;
          &__text {
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px;
          }
          &__wrapper {
            display: flex;
            width: 100%;
            max-width: 90px;
            gap: 8px;
            justify-content: flex-end;
            &__text {
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 22px;
            }
          }
        }
      }
    }
    &__tableCont {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      gap: 24px;
      &__table {
        width: 100%;
        .header-cell-small {
          width: 56px;
        }
        .tableCelltext-right {
          text-align: right;
        }
        .ant-table-cell.tableCelltext-right {
          text-align: right;
        }
        .table-row-text {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
        }
        .tableiconsWrapper {
          &__wrapper {
            width: 24px;
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            margin-left: auto;
            margin-right: auto;
          }
          display: flex;
          gap: 8px;
          width: 100%;
          max-width: 72px;
        }
      }
      &__pagination {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
}
@media screen and (max-width: 900px) {
  .orders-cont__content__pageCont {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    height: 100%;

    &__segmentWrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      gap: 8px;
    }
    &__cardCont {
      display: flex;
      width: 100%;
      gap: 12px;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      &__card {
        width: 100%;
        max-width: 900px;
        border-radius: 6px;
        border-left: 3px solid;
        &__titleLine {
          width: 100%;
          display: flex;
          justify-content: space-between;
          &__title {
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px;
          }
          &__month {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
          }
        }
        &__body {
          width: 100%;
          display: flex;
          justify-content: space-between;
          &__text {
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
          }
          &__wrapper {
            display: flex;
            width: 100%;
            max-width: 90px;
            gap: 8px;
            justify-content: flex-end;
            &__text {
              font-size: 20px;
              font-style: normal;
              font-weight: 600;
              line-height: 28px;
            }
          }
        }
      }
    }
    &__tableCont {
      gap: 12px;
      &__table {
        width: 100%;
        .header-cell-small {
          width: 56px;
        }
        .tableCelltext-right {
          text-align: right;
        }
        .ant-table-cell.tableCelltext-right {
          text-align: right;
        }
        .table-row-text {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
        }
        .tableiconsWrapper {
          &__wrapper {
            width: 24px;
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            margin-left: auto;
            margin-right: auto;
          }
          display: flex;
          gap: 8px;
          width: 100%;
          max-width: 72px;
        }
      }
      &__pagination {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-bottom: 10px;
      }
    }
  }
}
