.header-cont {
  display: flex;
  flex-direction: row;
  width: 100%;

  &__logo-wrapper {
    width: 100%;
    display: flex;
    max-width: 200px;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    justify-content: center;
    height: 64px;
    cursor: pointer;

    // height: 100vh;
    &__img-wrapper {
      width: 100%;
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
      gap: 16px;
      align-self: stretch;
    }
  }

  &__header {
    display: flex;
    padding: 0px 24px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 24px 0px 0px 0px;
    border-left: 3px solid;
    width: 100%;
    line-height: unset;
    // box-shadow: 3px 0px 0px 0px #FFF inset;

    &__MenuFoldOutlined {
      font-size: 24px;
      cursor: pointer;
    }

    &__text {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      /* 150% */
    }

    &__BellOutlined {
      font-size: 24px;
      cursor: pointer;
    }

    &__UserOutlined {
      font-size: 14px;
    }

    // &__imgWrapper {
    //   width: 20px;
    //   height: 20px;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   border-radius: 4px;
    //   // background: var(--colorTextLightSolid, #fff);
    //   gap: 8px;
    //   position: relative;
    //   cursor: pointer;
    //   &__circle {
    //     position: absolute;
    //     right: -3px;
    //     top: -3px;
    //     width: 6px;
    //     height: 6px;
    //     border-radius: 50%;
    //     border: 1px solid #FFFFFF;
    //   }
    // }
  }

  .menuClosed {
    width: 100%;
    max-width: 80px;
  }

  &__popover {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 147px !important;

    &__empty {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: rgba(0, 0, 0, 0.25);
      padding-top: 4px;
      padding-bottom: 4px;
    }

    &__list {
      &__container {
        width: 100%;
      }

      &__item {
        &__dot {
          margin-left: 5px;
          margin-right: 5px;

          &::after {
            content: "\2219";
          }
        }

        &__time {
          color: #49444499;
          font-size: 12px;
        }

        display: flex;
        justify-content: space-between;
        width: 100%;

      }

      &__icon {
        align-self: flex-start;
        margin-right: 10px;
      }

      display: flex;
      cursor: pointer;

      &:hover {
        background: #fafafa;
      }
    }

    &__content {
      width: 100%;
      display: flex;
      flex-direction: column;

      // gap: 12px;
      &__btn {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 4px;
      }

      &__divider {
        margin-top: 12px;
        margin-bottom: 12px;
        width: 100%;
      }
    }

    &__btn {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 4px;
    }
  }
}

@media screen and (max-width: 900px) {
  .header-cont {
    &__header {
      display: flex;
      padding: 0px 16px;
      border-left: 0px;
      height: 44px;

      &__img {
        width: 100%;
        max-width: 170px;
      }

    }

    .menuClosed {
      width: 100%;
      max-width: 80px;
    }

    &__popover {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 147px !important;

      &__content {
        width: 100%;
        display: flex;
        flex-direction: column;

        // gap: 12px;
        &__btn {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 4px;
        }

        &__divider {
          margin-top: 12px;
          margin-bottom: 12px;
          width: 100%;
        }
      }

      &__btn {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 4px;
      }
    }
  }
}