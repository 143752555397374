.addsalemodal-cont {
  border-radius: 8px;
  width: 100%;
  max-width: 300px;
  &__btn {
    width: 100%;
    max-width: 80px;
    padding: 0px 16px;
  }
  &__form {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    &__formItem {
      width: 100%;
      &__text {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }
  .ant-form-item-required.ant-form-item-no-colon::before {
    content: "" !important;
  }
}
