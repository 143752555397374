.metrcStrainModal-cont {
    border-radius: 8px;
    width: 100%;
    max-width: 520px;

    &__form {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;

        &__formItemWrapper {
            width: 100%;
            display: flex;
            align-items: flex-start;
            gap: 24px;

            &__formItem {
                width: 100%;

                &__text {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                }
            }
        }

        &__formItemWrapperSlider {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;

            &__formItem {
                width: 100%;

                &__text {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                }

                &__sliderTextWrapper {
                    width: 100%;
                    display: flex;
                    gap: 8px;
                    justify-content: space-between;

                    &__text {
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px;
                    }
                }
            }

        }
    }

    &__btn {
        width: 100%;
        max-width: 80px;
    }

    .ant-form-item-required.ant-form-item-no-colon::before {
        content: "" !important;
    }
}

@media screen and (max-width: 600px) {
    .metrcStrainModal-cont {
        max-width: 300px;

        &__btn {
            width: 100%;
            max-width: 80px;
        }

    }
}