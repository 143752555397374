.teamUserModal-cont {
  border-radius: 8px;
  width: 100%;
  max-width: 520px;
  &__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    &__formItemWrapper {
        width: 100%;
        display: flex;
        align-items: flex-start;
        gap: 24px;
        &__formItem {
          width: 100%;
          max-width: 224px;
          // display: inline-flex;
          // align-items: flex-end;
          &__text {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
          }
        }
        &__formItemSelect {
          width: 100%;
          &__select {
            width: 100%;
          }
        }
      }
}
  &__btn {
    width: 100%;
    max-width: 80px;
    height: 32px;
    // align-items: center;
  }
  .ant-form-item-required.ant-form-item-no-colon::before {
    content: "" !important;
  }
}
@media screen and (max-width: 600px){
  .teamUserModal-cont {
    max-width: 300px;
    // max-height: calc(100vh - 100px);
    &__form {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 12px;
      &__formItemWrapper {
          width: 100%;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          gap: 12px;
          &__formItem {
            width: 100%;
            max-width: 600px;
          }
        }
  }
    .ant-form-item-required.ant-form-item-no-colon::before {
      content: "" !important;
    }
  }
}