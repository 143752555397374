.globalSale-cont {
    border-radius: 8px;
    width: 100%;
    max-width: 300px;

    &__form {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;

        &__formItem {
            width: 100%;

            &__text {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
            }
        }
    }

    &__cancelBtn {
        display: none;
    }

    &__btn {
        width: 100%;
        margin: 0 !important;
    }

    .ant-form-item-required.ant-form-item-no-colon::before {
        content: "" !important;
    }

    .ant-form-item {
        margin-bottom: 0;
    }
}

@media screen and (max-width: 600px) {
    .globalSale-cont {
        &__form {
            gap: 8px;
        }
    }
}