.dashBoardHeader-cont {
  display: flex;
  padding: 0px 24px 80px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-left: 3px solid var(--Color, #fff);
  background: var(--ColorMenuDarkBg, #001529);
  position: relative;
  z-index: 0;
  box-shadow: 3px 0px 0px 0px #FFF inset;
  
}
