.creationModal-cont {
  border-radius: 8px;
  width: 100%;
  max-width: 520px;
  &__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    &__formItemWrapper {
      width: 100%;
      display: flex;
      gap: 24px;
      align-items: flex-start;
      &__formItem {
        width: 100%;
        max-width: 224px;
        &__text {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
        }
      }
      &__formItemSelect {
        width: 100%;
        &__select {
          width: 100%;
        }
      }
    }
  }
  &__btn {
    width: 100%;
    max-width: 80px;
  }
  // if the formItem field is required as result we have * but and our folowing logic will work properly but in design
  // i dont see asterix and this calss help us dont see asterix and the logic that we expected will work right
  .ant-form-item-required.ant-form-item-no-colon::before {
    content: "" !important;
  }
}
@media screen and (max-width: 600px){
  .creationModal-cont{
    max-width: 300px;
    &__form {
      gap: 12px;
      &__formItemWrapper {
        gap: 12px;
        flex-direction: column;
        &__formItem {
          width: 100%;
          max-width: 600px;
        }
      }
    }
  }
}
