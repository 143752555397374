.inventoryModal-cont {
    border-radius: 8px;
    width: 100%;
    max-width: 520px;

    &__subTitleWrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;

        &__text {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
        }
    }

    &__form {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;

        &__itemNameWrapper {
            width: 100%;

            &__text {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
            }
        }

        &__formItemWrapper {
            width: 100%;
            display: flex;
            align-items: flex-start;

            &__formItem {
                width: 100%;

                &__infoWarpper {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 12px;

                    &__table {
                        width: 100%;
                    }

                    &__descriptions {
                        width: 100%;
                    }
                }

                &__text {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                }
            }
        }
    }

    &__btn {
        width: 100%;
        max-width: 80px;
    }

    .ant-form-item-required.ant-form-item-no-colon::before {
        content: "" !important;
    }
}
@media screen and (max-width: 600px) {
    .inventoryModal-cont {
        max-width: 300px;
    }  
}