.changePasswordModal-cont {
  border-radius: 8px;
  width: 100%;
  max-width: 300px;
  &__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    &__formItem {
      width: 100%;
      &__text {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }
  &__btn {
    width: 100%;
    max-width: 80px;
    height: 32px;

    // align-items: center;
  }
  // if the formItem field is required as result we have * but and our folowing logic will work properly but in design
  // i dont see asterix and this calss help us dont see asterix and the logic that we expected will work right
  .ant-form-item-required.ant-form-item-no-colon::before {
    content: "" !important;
  }
}
